import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import styles from './styles.module.scss';

const NotOnlineNoteToRequesterDoctor = () => {
    return <p className={styles.NoteWrapper}>
        <LoadingAnimation/>
        <p className={styles.Title}>Связь прервалась</p>
        <p className={styles.NoteParagraph}>
            <p className={styles.NoteParagraphStart}>Если консультация завершена: </p>
            Mila пришлет уведомление, когда заключение от{'\u00A0'}врача будет готово.
        </p>
        <p className={styles.NoteParagraph}>
            <p className={styles.NoteParagraphStart}>Если вы не завершили консультацию: </p>
            ожидайте повторного звонка врача или напишите ему в{'\u00A0'}чат.
        </p>
    </p>;
}

export default NotOnlineNoteToRequesterDoctor;