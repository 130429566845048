import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { OneTimePassResponse, UserInfoResponse, TmkChat } from "src/types";

export const voxImplantApi = createApi({
	reducerPath: "voxImplantApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_API}/voximplant`,
	}),
	tagTypes: ["VoxImplant"],
	endpoints: (builder) => ({
		getUserInfo: builder.query<UserInfoResponse, { sessionId: string; appointmentId: string, isTmk: boolean }>({
			query: ({ sessionId, appointmentId, isTmk }) =>
				isTmk ? 
					`tmk-user-info?sessionId=${sessionId}&tmkAppointmentId=${appointmentId}` : 
					`user-info?sessionId=${sessionId}&appointmentId=${appointmentId}`,
		}),
		getOneTimePass: builder.query<OneTimePassResponse, { voximplantId: number; loginKey: string }>({
			query: ({ voximplantId, loginKey }) =>
				`one-time-password?voximplantId=${voximplantId}&loginKey=${loginKey}`,
		}),
		getCallStateFrequency: builder.query<number, {}>({
			query: () =>
				`call-state-frequency`,
		}),


	}),
});

export const {
	useGetOneTimePassQuery,
	useLazyGetOneTimePassQuery,
	useGetUserInfoQuery,
	useGetCallStateFrequencyQuery
} = voxImplantApi;
