import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import styles from './styles.module.scss';

const NotOnlineNoteToPerformerDoctor = () => {
    return <p className={styles.NoteWrapper}>
        <LoadingAnimation />
        <p className={styles.Title}>Связь прервалась</p>
        <p className={styles.NoteParagraph}>
            <p className={styles.NoteParagraphStart}>Если вы завершили консультацию: </p>
            вернитесь в Личный кабинет врача для написания заключения.
        </p>
        <p className={styles.NoteParagraph}>
            <p className={styles.NoteParagraphStart}>Если необходимо продолжить общение: </p>
            позвоните врачу еще раз или напишите ему в чат.
        </p>
    </p>;
}

export default NotOnlineNoteToPerformerDoctor;