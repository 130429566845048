import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import styles from './styles.module.scss';

const CallFailedNoteDoctor = () => {
    return (
        <p className={styles.NoteWrapper}>
            <LoadingAnimation />
            <p className={styles.Title}>Соединение не установлено</p>
            <p className={styles.NoteParagraph}>Вероятно, врач еще не в сети.<br/>Позвоните позже или напишите в чат.<br/>
                Если врач не отвечает длительное время - вы можете отменить консультацию</p>
        </p>
    );
}



export default CallFailedNoteDoctor;